.home {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
}

.home .banner {
  width: 100%;
  height: inherit;
  margin-top: 50px;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 100px;
}

.home .banner h1 {
  width: 100%;
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Anton", sans-serif;
  margin: 30px;
}

.home .banner h2 {
  color: rgb(0, 0, 0);
  font-size: 3rem;
  text-transform: uppercase;
  text-align: start;
  font-family: sans-serif;
}

.home .make-row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.home img {
  width: 100%;
  border-radius: 15px;
}

.loading_bar_bg {
  width: 100%;
  height: 20px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 100px;
}

.loading_bar {
  height: 20px;
  animation-name: anim;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border-radius: 100px;
}

@keyframes anim {
  0% {
    width: 0%;
    background-color: rgb(238, 36, 36);
  }
  20% {
    width: 20%;
    background-color: rgb(0, 67, 211);
  }
  40% {
    width: 40%;
    background-color: rgb(103, 151, 255);
  }
  60% {
    width: 60%;
    background-color: rgb(67, 212, 0);
  }
  80% {
    width: 80%;
    background-color: rgb(121, 0, 56);
  }
  100% {
    width: 100%;
    background-color: rgb(233, 190, 0);
  }
}
