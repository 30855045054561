.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer .footer-text {
  width: 100%;
  background-color: black;
}

.footer .footer-text h1 {
  font-size: 1.8rem;
  color: white;
  text-align: center;
  margin: 30px 0px;
  font-weight: bold;
  text-transform: uppercase;
}

.contact-address a {
  color: rgb(0, 44, 167);
  text-decoration: none;
}

.contact-address a:active {
  color: rgb(0, 44, 167);
}

.footer .contact-address {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  padding: 50px 0;
}

.footer .contact-address div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 15px;
}

.footer .contact-address i {
  color: rgb(0, 44, 167);
  font-size: 1.7rem;
  text-align: center;
  margin: 10px;
}

.footer .contact-address h1 {
  color: black;
  font-size: 1.2rem;
  text-align: center;
  margin: 5px;
  font-weight: bold;
}

.footer .contact-address h2 {
  color: black;
  font-size: 1rem;
  text-align: center;
  margin: 5px;
  font-weight: bold;
}

.footer .social-media {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-media a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  margin: 40px 0;
}

.social-media a:active {
  color: rgb(0, 44, 167);
}

.footer .social-media i {
  color: black;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
}

.footer .footer-last {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  padding: 30px;
}

.footer .footer-last h1 {
  color: white;
  font-size: 1rem;
  font-weight: bold;
}
