* {
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  font-size: 100%;
  box-sizing: border-box;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(255, 255, 255);
  height: 60px;
  position: fixed;
  z-index: 100;
  top: 0;
}

.header .menuBar {
  display: none;
}

.header .menuBar i {
  color: white
}

.header .matrixxDesigns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.header .matrixxDesigns img {
  width: 200px;
  margin: 0 30px;
  padding: 0;
}

.header .matrixxDesigns h1 {
  text-transform: uppercase;
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
  color: #161515;
}

.header .menubar-items {
  align-items: center;
}

.header .menubar-items ul {
  margin: 0px;
  color: #161515;
}

.header .menubar-items li {
  display: inline-block;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 20px;
}

.header .menubar-items li:hover {
  background-color: rgb(70, 243, 142);
  padding: 10px 30px;
}

.header .social {
  display: none;
}

@media screen and (max-width: 1108px) {
  .header .menuBar {
    display: block;
    color: black;
    font-size: 2rem;
    position: absolute;
    left: 0;
    margin-left: 33px;
  }
  .header .matrixxDesigns {
    margin: 0px;
    padding: 0;
    position: absolute;
  }
  .header .matrixxDesigns img {
    margin: 0;
    padding: 0;
  }
  .header .matrixxDesigns h1 {
    display: none;
  }
  .header .menubar-items {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
  .header .menubar-hold {
    background-color: rgba(46, 204, 113);
    position: absolute;
    width: 100%;
    color: #161515;
    top: 0;
    left: 0;
    padding: 10px 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-top: 60px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .header .menubar-items ul {
    width: 100%;
    color: #161515;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding: 0;
  }
  .header .menubar-items li {
    margin: 10px 0;
  }
  .header .social {
    display: flex;
    justify-content: space-around;
  }
  .header .social a {
    color: white;
    font-size: 1.5rem;
    margin: 10px;
    text-decoration: none;
    list-style: none;
  }
}