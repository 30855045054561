.product_list {
    width: 100%;
    border: none;
    border-radius: 15px;
    box-shadow: 2px 2px 6px 0px #2346692a;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    margin-top: 10px;
}

.product_list img {
    object-fit: fill;
    border-radius: 15px;
}

.product_list h4 {
    font-weight: bold;
    margin: 10px 20px;
    font-size: 1rem;
    background-color: white;
    color: black;
    width: fit-content;
}

.product_list h5 {
    font-weight: bold;
    margin: 10px 20px;
    font-size: 1rem;
    background-color: dodgerblue;
    color: white;
    padding: 5px 10px;
    width: fit-content;
    border-radius: 5px;
}